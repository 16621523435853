import React, { useContext, useState } from "react";

import * as yup from "yup";
import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns";

import CustomForm from "../../app/components/CustomForm";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Typography } from "@mui/material";
import { LanguageContext } from "../../app/context/LanguageContext";
import { sessionTypes } from "../../app/utils/enums";
import {
  postTraining,
  putTraining,
  selectPlan,
} from "../../app/reducers/plansSlice";

interface Props {
  edit?: boolean;
  annual?: any;
  training?: any;
  closeModal?: any;
}

const TrainingForm = (props: Props): React.ReactElement => {
  const { edit, annual, training, closeModal } = props;
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const { i18n } = useContext(LanguageContext);
  const annualPlans = useAppSelector(selectPlan);

  const fields = [
    {
      name: "day",
      label: i18n.plans.dayTime,
      type: "date",
      placeholder: i18n.plans.placDayTime,
      edit: edit ?? false,
    },
    {
      name: "time",
      label: i18n.plans.time,
      type: "time",
      placeholder: i18n.plans.placTime,
      edit: edit ?? false,
    },
    {
      name: "session_type",
      label: i18n.plans.sessionType,
      type: "select",
      selectValues: sessionTypes,
      placeholder: i18n.plans.placSessionType,
      edit: edit ?? false,
    },
    {
      name: "session_description",
      label: i18n.plans.sessionDesc,
      type: "text",
      placeholder: i18n.plans.placSessionDesc,
      edit: edit ?? false,
    },
  ];

  const init = {
    session_type: training?.session_type ?? "",
    session_description: training?.session_description ?? "",
    day: training?.day_time
      ? new Date(training?.day_time)
      : new Date(annualPlans?.valuesWeek[0]?.day).getTime() >
        new Date().getTime()
      ? new Date(annualPlans?.valuesWeek[0]?.day)
      : new Date(),
    time: training?.day_time
      ? new Date(utcToZonedTime(training?.day_time, "Etc/UTC"))
      : new Date(),
  };

  const scheme = yup.object({
    session_description: yup.string().required(i18n.plans.requiredField),
    session_type: yup.string().required(i18n.plans.requiredField),
    day: yup.string().required(i18n.plans.requiredField),
    time: yup.string().required(i18n.plans.requiredField),
  });

  const onFormSubmit = async (values: any) => {
    try {
      setDisabled(true);
      const date = format(values.day, "yyyy-MM-dd");
      const time = format(values.time, "HH:mm");

      const formData = {
        ...values,
        day_time: `${date as string}T${time as string}:00Z`,
        annual_plan: annual.id,
      };

      const editData = {
        session_type: values.session_type,
        session_description: values.session_description,
        day_time: `${date as string}T${time as string}:00Z`,
        annual_plan: annual.id,
        id: training?.id,
      };

      let res;

      if (!edit) res = await dispatch(postTraining(formData));

      if (edit) res = await dispatch(putTraining(editData));

      if (res?.meta.requestStatus === "fulfilled") {
        closeModal();
      } else {
        setError(i18n.auth.errors.error);
      }

      setDisabled(false);
    } catch (error) {
      setDisabled(false);
    }
  };

  return (
    <>
      {fields && fields?.length > 0 && (
        <CustomForm
          formFields={fields}
          initialValues={init}
          validationSchema={scheme}
          onFormSubmit={onFormSubmit}
          disabled={disabled}
          cancelFunction={closeModal}
        />
      )}
      {error !== "" && (
        <Typography variant="subtitle1" color={"error"} paddingTop={2}>
          {error}
        </Typography>
      )}
    </>
  );
};
export default TrainingForm;
