import { Button } from "@mui/material";
import React from "react";
import DefaultPalette from "../theme/palette";

interface Props {
  label?: string;
  onPress?: () => void;
  width?: string | number;
  mode?: "text" | "outlined" | "contained" | undefined;
  disabled?: boolean;
  backgroundColor?: string;
  textColor?: string;
  fontWeight?: string;
  textDecoration?: string;
  height?: string;
  marginRight?: string;
}

const CustomBlueButton: React.FC<Props> = ({
  label,
  onPress,
  width,
  mode,
  disabled,
  backgroundColor,
  textColor,
  fontWeight,
  textDecoration,
  height,
  marginRight,
}) => {
  const palette = DefaultPalette("dark", "custom");

  const backgroundButton = disabled
    ? palette.custom.gray
    : backgroundColor ?? palette.custom.blue;

  const textColorStyle = textColor ?? palette.custom.white;

  return (
    <Button
      disabled={disabled}
      style={{
        width: width ?? "100%",
        height: height ?? "40px",
        fontFamily: "Montserrat",
        fontWeight: fontWeight ?? "bold",
        fontSize: "87%",
        backgroundColor: backgroundButton,
        color: textColorStyle,
        textDecoration: textDecoration ?? "none",
        boxShadow: "none",
        marginRight: marginRight ?? "5px",
      }}
      variant={mode ?? "contained"}
      onClick={onPress}
    >
      {label}
    </Button>
  );
};

export default CustomBlueButton;
