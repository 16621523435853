import React, { useContext, useState } from "react";

import * as yup from "yup";

import CustomForm from "../../app/components/CustomForm";
import { useAppDispatch } from "../../app/hooks";
import { Typography } from "@mui/material";
import { LanguageContext } from "../../app/context/LanguageContext";
import { postTrainingReport } from "../../app/reducers/plansSlice";
import { MainContext } from "../../app/context/MainContext";
import { rpeValues } from "../../app/utils/enums";

interface Props {
  training?: any;
  closeModal?: any;
  setReload?: any;
}

const TrainingReportForm = (props: Props): React.ReactElement => {
  const { training, closeModal, setReload } = props;
  const { currentProfile } = useContext(MainContext);
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const { i18n } = useContext(LanguageContext);

  const fields = [
    {
      name: "text",
      label: i18n.plans.trainingText,
      type: "text",
    },
    {
      name: "duration_in_minutes",
      label: i18n.plans.duration,
      type: "text",
    },
    {
      name: "rpe",
      label: i18n.plans.rpe,
      type: "select",
      selectValues: rpeValues
    },
  
  ];

  const init = {
    text: "",
    duration_in_minutes: "",
    rpe: "1",
  };

  const scheme = yup.object({
    text: yup.string().required(i18n.plans.requiredField),
    rpe: yup.string().required(i18n.plans.requiredField),
    duration_in_minutes: yup.number()
      .typeError(i18n.plans.mustNumber)
      .required(i18n.plans.requiredField)
      .positive(i18n.plans.mustPositive),
  });

  const onFormSubmit = async (values: any) => {
    try {
      setDisabled(true);

      const formData = {
        ...values,
        training: training.id,
        profile: currentProfile.id,
      };
      let res = await dispatch(postTrainingReport(formData));

      if (res?.meta.requestStatus === "fulfilled") {
        setReload((v: any) => !v)
        closeModal()
      } else {
        setError(i18n.auth.errors.error);
      }

      setDisabled(false);
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };

  return (
    <>
      {fields && fields?.length > 0 && (
        <CustomForm
          formFields={fields}
          initialValues={init}
          validationSchema={scheme}
          onFormSubmit={onFormSubmit}
          disabled={disabled}
          cancelFunction={closeModal}
        />
      )}
      {error !== "" && (
        <Typography variant="subtitle1" color={"error"} paddingTop={2}>
          {error}
        </Typography>
      )}
    </>
  );
};
export default TrainingReportForm;
