const email = "info@adaptify.app"

const languages = {
  en: {
    appData: {
      name: "ADAPTIFY",
      home: "Home",
      goToMobile: "Please, for detailed view go to the mobile app",
      goToMobile1: "You can download it on",
      logout: "Logout",
      profile: "Profile",
      submit: "Submit",
      save: "Save",
      yes: "Yes",
      no: "No",
      dashboard: "Dashboard",
      areYouSure: "Are you sure?",
      hello: "Hello",
      annual: "Annual Plans",
      annualPlan: "Annual Plan",
      competitions: "Competitions",
      noCompetitions: "No competitions yet",
      week: "Weekly Plans",
      weeklyPlan: "Current Week",
      settings: "Settings",
      noAnnualPlan: "No annual plans yet",
      passValidationMin: "Must be at least 8 characters",
      passValidationMax: "Must be maximum 40 characters",
      passNotSame: "Passwords are not same",
      requiredField: "Required field",
      eventCalendar: "Event Calendar",
      noData: "No data yet.",
    },
    auth: {
      login: "Login",
      register: "Register",
      resetPassword: "Reset Password",
      logIn: "Log In",
      title: "ADAPTIFY",
      lblEmail: "Email",
      privacyPolicy: "Privacy Policy",
      termsOfConditions: "Terms of Conditions",
      lblPassword: "Password",
      lblNewPassword: "New password",
      lblRePassword: "Repeat password",
      forgotPassword: "Forgot Password?",
      enterEmail:
        "Enter a new email and we'll send you a link to reset your password.",
      cancel: "Cancel.",
      signInContinue: "Sign in to continue",
      alreadyAccount: "Already have an account?",
      linkRegister: "Register.",
      linkForgotPasssword: "Forgot Password?",
      contactUs: `Please contact us on ${email}`,
      alreadyRegistered: "Already registered?",
      errors: {
        invalidEmailFormat: "Must be email format",
        requiredEmail: "Email is required",
        requiredPassword: "Password is required",
        notValidCredential: "Not valid credential!",
        notValid: "Not valid!",
        notValidPassword: "Not valid password!",
        error: "Error!",
        tooShort: "The password is weak.",
        allFields: "All fields field may not be blank.",
        tooCommon: "This password is too common.",
        similarEmail: "The password is too similar to the email.",
      },
      success: {
        successForgot: "You successfully send request for Forgot Password. Please check instruction on email, spam or promotions.",
        successChangePass: "You successfully change password. Please check new credentials.",
      }
    },
    plans: {
      seasonAthlete: "Season - Athlete",
      notStartedYetSeason: "Season not started yet",
      lastWeekLoad: "Weekly and Monthly Overview",
      weeklyLoad: "Weekly Load",
      noLoads: "No Loads",
      competition: "Event",
      addCompetition: "Add event",
      editCompetition: "Edit event",
      deleteCompetition: "Delete event",
      compReport: "Event reports",
      noCompReport: "No event reports yet",
      addCompReport: "Add event report",
      editCompReport: "Edit event report",
      eventDetails: "Event details",
      trainingDetails: "Training details",
      addTraining: "Add Training",
      editTraining: "Edit training",
      deleteTraining: "Delete training",
      delete: "Delete",
      deleteTextEvent:
        "If you delete this event you won't be able to get it back... Are you sure you want to delete?",
      deleteTextTraining:
        "If you delete this training you won't be able to get it back... Are you sure you want to delete?",
      addEvent: "Add Event",
      addReport: "Add report",
      cancel: "Cancel",
      addTrainingReport: "Add training report",
      noTrainingReport: "No training reports yet",
      target: "Target",
      importance: "Importance",
      microcycle: "Microcycle",
      dateComp: "Date of event",
      location: "Location",
      nameComp: "Level",
      placTarget: "Enter Target",
      placImportance: "Choose importance",
      placLocation: "Enter Location",
      placName: "Enter Level",
      requiredField: "Required field",
      mustPositive: "Must be positive number",
      mustNumber: "Must be number",
      sessionNumber: "Session Number",
      sessionType: "Session Focus",
      sessionDesc: "Session Description",
      dayTime: "Date",
      time: "Time",
      placSessionType: "Choose session focus",
      placSessionDesc: "Choose session description",
      placDayTime: "Date",
      placTime: "Time",
      rpe: "RPE",
      from: "From:",
      reportDetails: "Report Details",
      technique: "Technical",
      tactic: "Tactical",
      physicalPreparation: "Physical",
      psychology: "Mental",
      evalAnal: "Evaluation/Analysis",
      trainingText: "Text",
      duration: "Duration (minutes)",
      chooseWeek: "Choose week",
      lblCurrentPassword: "Current password",
      lblNewPassword: "New password",
      traningEvent: "Traning / Event",
      description: "Description",
      report: "Report",
      reports: "Reports",
      chooseAthlete: "Choose Athlete",
      headersAnnual: {
        month: "MONTH",
        week: "Week",
        microcycle: "Microcycle",
        events: "Events",
        importance: "Importance",
        target: "Target",
      },
      headers: {
        monday: "Monday",
        tuesday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        saturday: "Saturday",
        sunday: "Sunday",
        weeklyLoad: "Weekly Load",
        dailyLoad: "Daily Load",
        currentWeek: "Current Week",
      },
      headerPastWeek: {
        trainingEvent: "Training focus / Event name",
        description: "Description",
        totalTime: "Total training time",
        rpe: "RPE",
        load: "Load",
      },
      headerFutureWeek: {
        time: "Time",
        trainingEvent: "Event/Training",
        trainingFocus: "Training focus",
        report: "Report",
      },
      headerMinWeek: {
        trainingEvent: "Time",
        description: "Traning / Event\nDescription",
        report: "Report",
      },
      competitionData: {
        level: "Level:",
        location: "Location:",
        dateOfComp: "Date of competition:",
        importance: "Importance:",
        target: "Target:",
        athlete: "Athlete:",
      },
      trainingData: {
        sessionFocus: "Session focus:",
        sessionDescription: "Session description:",
        dayTime: "Day/time:",
        athlete: "Athlete:",
        duration: "Duration (minutes):",
        text: "Text:",
        rpe: "RPE:",
      },
    },
    profile: {
      profile: "Profile",
      createProfile: "Create profile",
      editProfile: "Edit profile",
      deleteAccount: "Delete account",
      changePassword: "Change password",
      signOut: "Sign Out",
      name: "Name",
      club: "Club",
      dateOfBirth: "Date of birth",
      weapon: "Weapon",
      athlete: "Athlete",
      nameRequired: "Name is required",
      dateRequired: "Date is required",
      weaponRequired: "Weapon is reqired",
      clubRequired: "Club is required",
      sportScienceRole: "Sport science and medicine role",
      role: "Role:",
      sport: "Sport",
      sportProfile: "Sport:",
      discipline: "Discipline",
      disciplineProfile: "Discipline:",
      disciplineRequired: "Discipline is required",
      sportRequired: "Sport is required",
      sportRoleRequired: "Sport science and medicine role is required",
      deleteTitle: "Enter the password to confirm the deletion of the account",
    },
    privacyPoliciy: {
      adaptifyApp: "ADAPTIFY APP - Privacy Policy",
      // privacyPolicy: "Privacy Policy",
      lastUpdated: "Last Updated: 09/18/2023",
      aboutPrivicy:
        "This Privacy Policy ('Policy') outlines how Digital Sport Solutions PTE.LTD ('We,' 'Us,' or 'Our') collects, uses, discloses, and safeguards personal information obtained through the use of ADAPTIFY ('App').",
      information: "1. Information We Collect",
      personalInformation:
        " 1.1 Personal Information: We may collect certain personal information that you provide directly when using our App, such as [examples: name, email address, phone number]. This information is collected with your consent and may be used to [describe purposes, e.g., provide personalized experiences, contact you regarding updates].",
      deviceInformation:
        "1.2 Device Information: We may collect certain information about your device, including [examples: device type, operating system, device identifiers], to ensure compatibility and improve our services.",
      usegeData:
        "1.3 Usage Data: We collect data on how you interact with our App, including [examples:  pages visited, features used, time spent]. This information helps us enhance user experience and optimize our App's functionality.",
      useOfInformation: "2. Use of Information",
      useCollectedInformation:
        "2.1 We use the collected information for purposes including but not limited to [describe purposes, e.g., providing, maintaining, and improving the App, personalizing content, responding to user inquiries].",
      emailAddress:
        "2.2 We may use your email address to send you [describe emails, e.g., updates, newsletters] that you can opt out of at any time by [describe opt-out process].",
      disclosureOfInformation: "3. Disclosure of Information",
      sharePersonalInformation:
        "3.1 We may share personal information with [describe parties, e.g., service providers, business partners] to facilitate App-related services and operations.",
      discloseInf:
        "3.2 We may disclose information if required by law, legal process, or government request.",
      datSecurity: "4. Data Security",
      security:
        "4.1 We employ reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.",
      childrenPrivacy: "5. Children's Privacy",
      childrenText:
        "5.1 Our App is not intended for use by children under the age of [appropriate age, e.g., 13]. We do not knowingly collect personal information from children under this age.",
      choices: "6. Your Choices",
      choicesText1:
        "6.1 You may update, correct, or delete your personal information by delete your account.",
      choicesText2:
        "6.2 You can opt out of [e.g., receiving promotional emails] by unsubscribing from those services.",
      links: "7. Third-Party Links",
      linksText1:
        "7.1 Our App may contain links to third-party websites or services. We are not responsible for their content or privacy practices. We encourage you to review their Privacy Policies.",
      changes: "8. Changes to Privacy Policy",
      changesText1:
        "8.1 We reserve the right to update this Privacy Policy as needed. We will notify you of any material changes through in-app notification and email.",
      contactUs: "9. Contact Us",
      contactUsText1:
        `9.1 For questions, concerns, or requests related to this Privacy Policy, you can contact us at ${email}.`,
      contactUsText2:
        " By using our App, you consent to the practices described in this Privacy Policy. If you do not agree with this Policy, please refrain from using our App.",
      digitalSport1: "DIGITAL SPORT SOLUTIONS PTE.LTD",
      digitalSport2: "160 Robinson Road #14-04",
      digitalSport3: "Singapore Business Federation Center",
      digitalSport4: "Singapore 068914",
    },
    termsOfConditions: {
      terms: "ADAPTIFY APP - General conditions and Terms of use",
      termsText:
        "The general conditions and terms of use of the ADAPTIFY application and website (hereinafter: General Terms) define the conditions of access and use of the ADAPTIFY mobile application and website (hereinafter: application). By using the application, you confirm that you agree to the terms stated here. The application was developed and is administered by the Company Digital Sport Solutions PTE.LTD Singapore. Digital Sport Solutions PTE.LTDSingapore (hereinafter: The Company) reserves the right to terminate or partially or completely change the Application or the General conditions and terms of use at any time. If you do not agree to these terms, please refrain from using our application and website.",
      acceptableUse: "1. Acceptable Use",
      acceptableUsetext1: "1.1 You agree to use our application and services only for lawful purposes and in accordance with all applicable laws and regulations.",
      acceptableUsetext2: "1.2 You shall not engage in any activity that disrupts or interferes with the proper functioning of our application or services.",
      acceptableUsetext3: "1.3 You shall not use our application or services for any unauthorized, harmful, or fraudulent purposes.",
      intellectualProperty: "2. Intellectual Property",
      intellectualPropertyText1: "2.1 All content and materials on our application, including but not limited to text, graphics, logos, images, audio clips, video clips, and software, are protected by copyright and other intellectual property laws.",
      intellectualPropertyText2: "2.2 You may not copy, reproduce, distribute, modify, create derivative works, publicly display, or otherwise exploit any content on our application without the prior written consent of the Company.",
      userAccounts: "3. User Accounts",
      userAccountsText1: "3.1 In order to access certain features or services, you may need to create a user account. You agree to provide accurate and complete information during the account registration process.",
      userAccountsText2: "3.2 You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.",
      userAccountsText3: "3.3 The Company reserves the right to suspend or terminate your account at its discretion, without notice, if you violate any terms of this Agreement.",
      disclaimer: "4. Disclaimer of Warranties",
      disclaimerText1: "4.1 The Company provides its application and services 'as is' and does not make any warranties, express or implied, regarding the accuracy, completeness, reliability, or availability of its application and services.",
      disclaimerText2: "4.2 The Company disclaims all warranties of merchantability, fitness for a particular purpose, and non-infringement.",
      limitationOfLiability: "5. Limitation of Liability",
      limitationOfLiabilityText1: "5.1 The Company shall not be liable for any indirect, consequential, incidental, special, or punitive damages arising out of or in connection with your use of the application and services.",
      limitationOfLiabilityText2: "5.2 In no event shall the Company's liability exceed the amount paid by you, if any, for accessing our services.",
      modificationsToTerms: "6. Modifications to Terms",
      modificationsToTermsText1: "6.1 The Company reserves the right to modify or update these terms at any time. Any changes will be effective upon posting to the Company website",
      modificationsToTermsText2: "6.2 Continued use of the application and services after any modifications to the terms constitutes your acceptance of the revised terms.",
      governingLaw: "7. Governing Law",
      governingLawText1: "7.1 This Agreement shall be governed by and construed in accordance with the laws of Republic of Singapore.",
      contactInfo: "8. Contact Information",
      contactInfoText1: `8.1 For any questions or concerns regarding this Agreement, you may contact us at ${email}`,
      contactInfoText2: "By accessing or using our application and services, you acknowledge that you have read, understood, and agreed to this General conditions and terms of use. If you do not agree with these general conditions and terms of use, please do not use our application and services.",
      
    },
  },
  zh: {
    appData: {
      name: "Omni Spocket",
      home: "Home",
      logout: "Logout",
      profile: "Profile",
      submit: "Submit",
      yes: "Yes",
      no: "No",
      areYouSure: "Are you sure?",
      hello: "Hello",
      annual: "Annual Plans",
      annualPlan: "Annual Plan",
      competitions: "Competitions",
      noCompetitions: "No competitions yet",
      week: "Weekly Plan",
      noAnnualPlan: "No annual plans yet",
    },
    auth: {
      login: "Login",
    },
    plans: {
      competition: "Competition",
      addCompetition: "Add competition",
      compReport: "Competition reports",
      noCompReport: "No competition reports yet",
      addCompReport: "Add competition report",
      target: "Target",
      importance: "Importance",
      dateComp: "Date of competition",
      location: "Location",
      nameComp: "Name of competition",
      requiredField: "Required field",
      sessionNumber: "Session Number",
      sessionType: "Session Type",
      sessionDesc: "Session Description",
      dayTime: "Day / Time",
      rpe: "RPE",
    },
    profile: {
      name: "Name",
      club: "Club",
      dateOfBirth: "Date of birth",
      weapon: "Weapon",
      athlete: "Athlete",
      nameRequired: "Name is required",
      dateRequired: "Date is required",
      weaponRequired: "Weapon is reqired",
      clubRequired: "Club is required",
    },
  },
};

export default languages;
