import React, { useContext, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getAnnualPlan,
  getAnnualPlans,
  getWeekSessionValues,
  resetValuesWeekState,
  selectPlan,
} from "../../reducers/plansSlice";
import { LanguageContext } from "../../context/LanguageContext";
import WeeklyTable from "./WeeklyTable";
import CurrentWeeklyPlanPicker from "./CurrentWeeklyPlanPicker";
import NewRow from "../NewRow";
import { Box, Typography } from "@mui/material";
import {
  convertToDotDate,
  getFirstAndLastDateGetTime,
  getFormattedDate,
  getSeasonMonthsAndWeeks,
  setCompetitionsToCalendar,
} from "../../resources/simpleFunction";
import DefaultPalette from "../../theme/palette";
import CustomBlueButton from "../CustomBlueButton";
import CustomModal from "../CustomModal";
import CompetitionForm from "../../../pages/forms/CompetitionForm";
import TrainingForm from "../../../pages/forms/TrainingForm";
import { MainContext } from "../../context/MainContext";
import { Role } from "../../utils/enums";
import WeeklyPlanPicker from "./WeeklyPlanPicker";
import EmptyComponent from "../EmptyComponent";
import { SportsHandball } from "@mui/icons-material";
import WeeklyPickerAthlete from "./WeeklyPickerAthlete";
import { ca } from "date-fns/locale";

interface Props {
  weekStartDate: any;
  weekEndDate: any;
  mc?: any;
  allWeeks?: any;
  plansForPicker?: any;
  handleChangeCurrent?: any;
}

const WeeklyTableView = (props: Props): React.ReactElement => {
  const {
    weekStartDate,
    weekEndDate,
    mc,
    allWeeks,
    plansForPicker,
    handleChangeCurrent,
  } = props;
  const { currentProfile } = useContext(MainContext);
  const { i18n } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);
  const [loadValues, setLoadValues] = useState<any>();

  const [startDayTime, setStartDayTime] = useState(weekStartDate);
  const [endDayTime, setEndDayTime] = useState(weekEndDate);
  const [mcNumber, setMcNumber] = useState(mc);
  const [calendarData, setCalendarData] = useState<any>([]);

  const annualPlans = useAppSelector(selectPlan);
  const dispatch = useAppDispatch();
  const palette = DefaultPalette("dark", "custom");

  const [eventModal, setEventModal] = useState(false);
  const [trainingModal, setTrainingModal] = useState(false);
  const [reload, setReload] = useState(false);

  const [valueCurrent, setValueCurrent] = useState(false);
  const [calendarCondition, setCalendarCondition] = useState<any>();

  const currentDate = new Date().getTime();

  const onSelect = async (selectedItem: any) => {
    try {
      setLoading(true);
      let res = await dispatch(getAnnualPlan(selectedItem));
      if (res.meta.requestStatus === "fulfilled") {
        const loads = await dispatch(
          getWeekSessionValues({
            id: res.payload?.id,
            start_week: `${res.payload?.season?.start_date}T00:00:00`,
            end_week: `${res.payload?.season?.end_date}T23:59:59`,
          })
        );
        if (loads.meta.requestStatus === "fulfilled")
          if (loads?.payload.length > 0) {
            setLoadValues(loads?.payload.map((v: any) => v.load));
          } else {
            setLoadValues([]);
          }
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isActive = true;
    const changeCalendar = async () => {
      const calendarResult = getSeasonMonthsAndWeeks(
        annualPlans?.value?.season?.start_date,
        annualPlans?.value?.season?.end_date
      );
      const updatedCalendar = await setCompetitionsToCalendar(
        calendarResult,
        annualPlans?.valuesCompetition
      );
      const data: any = await Promise.all(updatedCalendar);
      setCalendarData(data);
      setCalendarCondition(getFirstAndLastDateGetTime(data));
    };

    const onScreenOpening = async (): Promise<void> => {
      try {
        setLoading(true);
        if (isActive) await changeCalendar();
        if (isActive) await dispatch(getAnnualPlans());
        if (isActive && annualPlans?.value?.id)
          await onSelect(annualPlans?.value?.id);

        const condition = plansForPicker.filter(
          (v: any) => v?.annualPlanId === annualPlans.value?.id
        );

        if (condition.length === 0) {
          await dispatch(resetValuesWeekState());
          setCalendarData([]);
        }

        setLoading(false);
      } catch (error: any) {
        setLoading(false);
      }
    };
    onScreenOpening();
    void onScreenOpening();
    return () => {
      isActive = false;
    };
  }, [dispatch, annualPlans?.value.id]);

  const objectDate =
    annualPlans.valuesWeek[6]?.day &&
    new Date(annualPlans.valuesWeek[6]?.day).getTime();

  const styles = {
    textStyle: {
      fontSize: "18px",
      color: palette.custom.lightGray,
    },
    textChooseAthelete: {
      fontSize: "26px",
      color: palette.custom.lightGray,
      marginTop: 30,
    },
    boxRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100vw",
      margin: "auto",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  };

  const onChangeAthleteInWeek = async (selectedItem: any) => {
    const res = await dispatch(getAnnualPlan(selectedItem));
    if (res.type.includes("fulfilled")) {
      const calendarResult = getSeasonMonthsAndWeeks(
        res.payload.season?.start_date,
        res.payload.season?.end_date
      );
      const updatedCalendar = await setCompetitionsToCalendar(
        calendarResult,
        annualPlans?.valuesCompetition
      );

      const data: any = await Promise.all(updatedCalendar);
      setCalendarData(data);
      const lastWeeks = data[data.length - 1].weeks;
      const lastWeek = lastWeeks[lastWeeks.length - 1];
      if (mcNumber >= lastWeek.number) {
        setMcNumber(lastWeek.number);
        setEndDayTime(getFormattedDate(lastWeek.week_end_date));
        setStartDayTime(getFormattedDate(lastWeek.week_start_date));
        setReload((v) => !v);
      } else {
        data.map((el: any) => {
          el.weeks.map((week: any) => {
            if (week.number === mcNumber) {
              setMcNumber(mcNumber);
              setEndDayTime(getFormattedDate(week.week_end_date));
              setStartDayTime(getFormattedDate(week.week_start_date));
              setReload((v) => !v);
            }
          });
        });
      }
    }
  };

  return (
    <div>
      <Box sx={styles.boxRow}>
        <Box
          sx={{
            display: "flex",
            width: "30vw",
          }}
        >
          {allWeeks ? (
            <WeeklyPickerAthlete
              annualPlans={annualPlans}
              onSelect={onSelect}
              currentProfile={currentProfile}
              allWeeks={allWeeks}
              setCurrentWeek={(selectedItem: any) => {
                onChangeAthleteInWeek(selectedItem);
              }}
              currentMc={mcNumber}
            />
          ) : (
            <CurrentWeeklyPlanPicker
              handleChange={handleChangeCurrent}
              defaultWeek={mc}
              currentPlan={annualPlans.value}
              setValueCurrent={setValueCurrent}
              setCurrentPlan={async (selectedItem: any) => {
                await dispatch(
                  getAnnualPlan(
                    currentProfile.role === Role.Athlete
                      ? await selectedItem.value
                      : await selectedItem
                  )
                );
                setReload((v) => !v);
              }}
              plansForPicker={plansForPicker}
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "40vw",
          }}
        >
          <Typography style={styles.textStyle}>
            {`${startDayTime} - ${endDayTime}`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "30vw",
          }}
        >
          {annualPlans.valuesWeek &&
            annualPlans.valuesWeek.length > 0 &&
            currentDate < objectDate &&
            currentProfile.role !== Role.Athlete && (
              <React.Fragment>
                <CustomBlueButton
                  onPress={() => {
                    setEventModal(true);
                  }}
                  label={i18n.plans.addEvent}
                  width={"40%"}
                />
                <CustomBlueButton
                  onPress={() => {
                    setTrainingModal(true);
                  }}
                  label={i18n.plans.addTraining}
                  width={"40%"}
                />
              </React.Fragment>
            )}
        </Box>
      </Box>
      <NewRow height={30} />
      {convertToDotDate(startDayTime).getTime() >= calendarCondition?.first ? (
        <React.Fragment>
          {annualPlans?.value && calendarData?.length > 0 ? (
            <WeeklyTable
              reload={reload}
              setReload={setReload}
              mc={mcNumber}
              startDayTime={startDayTime}
              endDayTime={endDayTime}
              setValueCurrent={setValueCurrent}
              setStartDayTime={setStartDayTime}
              setEndDayTime={setEndDayTime}
              setMcNumber={setMcNumber}
              calendarData={calendarData}
            />
          ) : (
            <EmptyComponent
              paddingTop="5rem"
              fontSize={"h4"}
              text={i18n.plans.chooseAthlete}
              icon={
                <SportsHandball
                  sx={{ fontSize: "3rem", color: palette.custom.gray }}
                />
              }
            />
          )}
        </React.Fragment>
      ) : (
        <EmptyComponent
          paddingTop="5rem"
          fontSize={"h4"}
          text={i18n.plans.notStartedYetSeason}
          icon={
            <SportsHandball
              sx={{ fontSize: "3rem", color: palette.custom.gray }}
            />
          }
        />
      )}
      {eventModal && (
        <CustomModal
          open={eventModal}
          onClose={() => {
            setEventModal(false);
          }}
          text={i18n.plans.addEvent}
        >
          <CompetitionForm
            annual={annualPlans.value}
            closeModal={() => {
              setEventModal(false);
              setReload((v) => !v);
            }}
          />
        </CustomModal>
      )}
      {trainingModal && (
        <CustomModal
          open={trainingModal}
          onClose={() => {
            setTrainingModal(false);
          }}
          text={i18n.plans.addTraining}
        >
          <TrainingForm
            annual={annualPlans.value}
            closeModal={() => {
              setTrainingModal(false);
              setReload((v) => !v);
            }}
          />
        </CustomModal>
      )}
    </div>
  );
};

export default WeeklyTableView;
